import { render, staticRenderFns } from "./CoreBlockContainer.vue?vue&type=template&id=7ba7698e"
import script from "./CoreBlockContainer.vue?vue&type=script&lang=js"
export * from "./CoreBlockContainer.vue?vue&type=script&lang=js"
import style0 from "./CoreBlockContainer.vue?vue&type=style&index=0&id=7ba7698e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../Core.Generator/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreBlockContainerToggle: require('/usr/src/app/Core.Library/src/components/block/container/CoreBlockContainerToggle.vue').default,CoreBlockContainerTabs: require('/usr/src/app/Core.Library/src/components/block/container/CoreBlockContainerTabs.vue').default,CoreBlockContainerAccordion: require('/usr/src/app/Core.Library/src/components/block/container/CoreBlockContainerAccordion.vue').default,CoreIcon: require('/usr/src/app/Core.Library/src/components/icon/CoreIcon.vue').default})
